import React, { useState } from "react";
import Rating from "@mui/material/Rating";

import { CloseModal } from "../../assets/images";

import "./giveEmployeeReviewModal.scss";
import { postEmployeeReview } from "../../redux/shiftSlice";
import { useDispatch } from "react-redux";

export const GiveEmployeeReviewModal = ({ setReviewModalOpen, shiftData }) => {
  const [starValue, setStarValue] = useState();
  const [patientCare, setPatientCare] = useState();
  const [professionalism, setProfessionalism] = useState();
  const [attendance, setAttendance] = useState();
  const [textFeedback, setTextFeedback] = useState();
  const [reviewError, setReviewError] = useState();
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();

  const submitHandler = () => {
    if (!patientCare ||!professionalism ||!attendance || !textFeedback)
      return setReviewError("Please fill all the fields");

    // if (textFeedback.length < 50)
    //   return setReviewError("Please write detailed feedback");

    setReviewError();
    const dispatchBody = {
      employeeUUID: shiftData.employee,
      ShiftUUID: shiftData.shift.uuid,
      textFeedBack: textFeedback,
      rating: starValue,
      patient_care: patientCare,
      professionalism: professionalism,
      attendance: attendance,
    };
    console.log("dispatchBody: ", dispatchBody);
    dispatch(postEmployeeReview(dispatchBody)).then((data) => {
      console.log({ data });
      data.meta.requestStatus == "fulfilled" && setSuccess(true);
    });
  };

  if (success)
    return (
      <div className="give-employee-review-modal-wrapper">
        <div
          className="give-employee-review-modal"
          style={{ minWidth: "500px", minHeight: "250px" }}
        >
          <div
            className="close-btn"
            onClick={() => setReviewModalOpen(false)}
            style={{
              cursor: "pointer",
              right: "16px",
              position: "absolute",
              top: "16px",
            }}
          >
            <div
              className="close-btn"
              onClick={() => setReviewModalOpen(false)}
            >
              <CloseModal />
            </div>
          </div>
          <h2
            style={{
              color: "green",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            Review Submitted
          </h2>
        </div>
      </div>
    );
  return (
    <div className="give-employee-review-modal-wrapper">
      <div className="give-employee-review-modal">
        <div className="header">
          <h2>Feedback</h2>
          <div className="close-btn" onClick={() => setReviewModalOpen(false)}>
            <CloseModal />
          </div>
        </div>
        {/* <section className="review-row">
          <div>Rating:</div>
          <div>
            <Rating
              name="simple-controlled"
              size="large"
              value={starValue}
              onChange={(event, newValue) => {
                setStarValue(newValue);
              }}
            />
          </div>
        </section> */}
        <section className="review-row">
          <div>Patient care:</div>
          <div>
            <Rating
              name="simple-controlled"
              size="large"
              value={patientCare}
              onChange={(event, newValue) => {
                setPatientCare(newValue);
              }}
            />
          </div>
        </section>
        <section className="review-row">
          <div>Professionalism:</div>
          <div>
            <Rating
              name="simple-controlled"
              size="large"
              value={professionalism}
              onChange={(event, newValue) => {
                setProfessionalism(newValue);
              }}
            />
          </div>
        </section>
        <section className="review-row">
          <div>Attendance:</div>
          <div>
            <Rating
              name="simple-controlled"
              size="large"
              value={attendance}
              onChange={(event, newValue) => {
                setAttendance(newValue);
              }}
            />
          </div>
        </section>
        <div className="review-rows2">
          <div className="bold-text" style={{ marginTop: "12px" }}>
            Review
          </div>
          <textarea
            className="text-area"
            onChange={(e) => setTextFeedback(e.target.value)}
            style={{ gridColumn: "1/-1", width: "305px", height: "190px" }}
            placeholder="Give review..."
          />
        </div>
        {reviewError && (
          <div className="error" style={{ marginTop: "12px" }}>
            {reviewError}
          </div>
        )}
        <button
          className="btn"
          onClick={submitHandler}
          style={{ fontWeight: "600" }}
        >
          Submit
        </button>
      </div>
    </div>
  );
};
