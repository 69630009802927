import React, { useEffect, useState } from "react";
import useCookies from "react-cookie/cjs/useCookies";
import { Navigate, Route, Routes } from "react-router-dom";
import { onMessageListener } from "./firebase";
import { takeToken } from "./firebase";
import axios from "axios";
import "./firebase";
import {
  // AgencyDashboard,
  AgencyListContainer,
  AgencySettingsContainer,
  // ApplicationsContainer,
  // EmployeeApplicationContainer,
  // EmployeeDetailsPage,
  // FacilityDetails,
  // HomePage,
  // Login,
  NotificationHomePage,
  ProfileSettingsPage,
  // RequestsContainer,
  SuperAdminDashboard,
  // TimeSheetPage,
  // ViewAllAvailableNow,
  // ViewAllTopRated,
} from "./containers";
// import CurrentContainer from "./containers/currentContainer/currentContainer";
import { ChangeImage } from "./containers/changeImage/changeImage";
import AdminLoginPage from "./containers/adminLoginPage/adminLoginPageContainer";
import AgencyDetails from "./containers/agencyDetails/agencyDetails";
// import AllTimeSheetPage from "./containers/allTimesheetPage/AllTimeSheetPage";
// import CalendarPage from "./containers/calendarPage/CalendarPage";
import ChangePassword from "./containers/changePassword/ChangePassword";
import SuccessPage from "./containers/successPage/SuccessPage";
import ChangeMyPassword from "./containers/changeMyPassword/changeMyPassword";
import ForgotPassword from "./containers/forgotPassword/forgotPassword";
// import { removeAllCookiesJs } from "./containers/profileSettingsPage/profileSettingsPage";

import "./styles/appStyles.scss";
import "./app.scss";
function App() {
  const [cookies] = useCookies(["token"]);

  if (cookies?.token?.access) axios.defaults.headers.common["Authorization"] = `Bearer ${cookies.token.access}`;

  /* <firbase notificagions start> */
  const [isTokenFound, setTokenFound] = useState(false);
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: "", body: "" });
  console.log("notification: ", notification);
  takeToken(setTokenFound);
  onMessageListener()
    .then((payload) => {
      setShow(true);
      setNotification({
        title: payload.notification.title,
        body: payload.notification.body,
      });
    })
    .catch((err) => console.log("failed: ", err));
  /* <firbase notificagions end> */

  if (!cookies.token || cookies.token === undefined) {
    return (
      <Routes>
        <Route path="/login" element={<AdminLoginPage />} />
        <Route path="/resetPassword" element={<ChangePassword />} />
        <Route path="/success" element={<SuccessPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  } else if (cookies.token.user_type == "Admin") {
    return (
      <Routes>
        <Route path="/login" element={<Navigate to="/admin-dashboard" />} />
        <Route path="/" element={<Navigate to="/admin-dashboard" />} />
        <Route path="/admin-dashboard" element={<SuperAdminDashboard />} />
        <Route path="/agency-list" element={<AgencyListContainer />} />
        <Route path="/agency-settings/:uuid" element={<AgencySettingsContainer />} />
        <Route path="/profile" element={<ProfileSettingsPage />} />
        <Route path="/agency-details/:uuid" element={<AgencyDetails />} />
        <Route path="/notifications" element={<NotificationHomePage />} />
        <Route path="/change-password" element={<ChangeMyPassword />} />
        <Route path="/resetPassword" element={<ChangePassword />} />
      </Routes>
    );
  }
}

export default App;
