import { createAsyncThunk } from "@reduxjs/toolkit";
import { createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// fetchFacilityProfile
export const fetchFacilityProfile = createAsyncThunk(
  "Facility/fetchProfile",
  async (uuid) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/facilities/${uuid}`
    );
    return response.data;
  }
);

// fetchAllFacilities
export const fetchAllFacilities = createAsyncThunk(
  "Facility/FetchProfile",
  async (_, thunk) => {
    try {
      // if (thunk.getState().facilitySlice.allFacilities?.res)
      //   return thunk.getState().facilitySlice.allFacilities.res;
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/facilities/`
      );
      return response.data;
    } catch (err) {
      return thunk.rejectWithValue(err.response.data);
    }
  }
);

// deleteFacility
export const deleteFacility = createAsyncThunk(
  "facility/deleteFacility",
  async (facilityUUID, { dispatch }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/employees/${facilityUUID}/delete`
      );
      dispatch(fetchAllFacilities());
      return facilityUUID;
    } catch (err) {
      console.log(err);
      // dispatch(fetchAllEmployees());
    }
  }
);

// createNewFacility
export const createNewFacility = createAsyncThunk(
  "Facility/CreateNew",
  async (body, thunk) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/facilities/create`,
        body
      );
      thunk.dispatch(fetchAllFacilities());
      return response.data;
    } catch (error) {
      return thunk.rejectWithValue(error.response.data.profile.user);
    }
  }
);

const initialState = {
  profile: {
    status: "intial state",
    res: {},
    error: {},
  },
  createNew: {
    status: "initial state",
    res: {},
    error: null,
  },
  allFacilities: {
    status: "initial state",
    res: null,
    error: null,
  },
};

const facilitySlice = createSlice({
  name: "FacilitySlice",
  initialState,
  extraReducers: {
    // fetchFacilityProfile
    [fetchFacilityProfile.pending]: (state) => {
      state.profile.status = "loading";
    },
    [fetchFacilityProfile.fulfilled]: (state, action) => {
      state.profile.status = "success";
      state.profile.res = action.payload;
      state.profile.error = null;
    },
    [fetchFacilityProfile.rejected]: (state, action) => {
      state.profile.status = "failed";
      state.profile.res = {};
      state.profile.error = action.error;
    },

    //createNewFacility

    [createNewFacility.pending]: (state) => {
      state.createNew.status = "loading";
    },
    [createNewFacility.fulfilled]: (state, action) => {
      state.createNew.status = "success";
      state.createNew.res = action.payload;
      state.createNew.error = null;
    },
    [createNewFacility.rejected]: (state, action) => {
      state.createNew.status = "failed";
      state.createNew.res = {};
      state.createNew.error = action.error;
    },

    // fetchAllFacilities

    [fetchAllFacilities.pending]: (state) => {
      state.allFacilities.status = "loading";
    },
    [fetchAllFacilities.fulfilled]: (state, action) => {
      state.allFacilities.status = "success";
      state.allFacilities.res = action.payload;
      state.allFacilities.error = null;
    },
    [fetchAllFacilities.rejected]: (state, action) => {
      state.allFacilities.status = "failed";
      state.allFacilities.res = {};
      state.allFacilities.error = action.error;
    },

    // deleteFacility
    [deleteFacility.pending]: (state) => {
      state.allFacilities.status = "loading";
    },
    [deleteFacility.fulfilled]: (state, action) => {
      state.allFacilities.status = "success";
      state.allFacilities.res = state.allFacilities.res.filter(
        (facility) => facility.uuid !== action.payload
      );
      state.allFacilities.error = null;
    },
  },
});

export default facilitySlice.reducer;
