import React from "react";
import { useSelector } from "react-redux";
import { Eye } from "../../assets/images";
import { getImage } from "../../utils/getAwsFile";
import { openPdfFromBase64 } from "../../utils/openPdfFromBase64";
import "./documentDetails.scss";

function openDocument(documentName, employeeProfileRes) {
  const aws_object_key = employeeProfileRes.files.find(
    (doc) => doc.type === documentName
  )?.aws_object_key;
  aws_object_key &&
    getImage(aws_object_key).then((data) => openPdfFromBase64(data));
}

const documentExits = (documentName, employeeProfileRes) => {
  return employeeProfileRes.files.find((doc) => doc.type === documentName);
};

const DocumentDetails = ({ icon, label, fileName }) => {
  const employeeProfileRes = useSelector(
    (state) => state.employeeSlice.Profile.res
  );

  const does = documentExits(label.toUpperCase(), employeeProfileRes);

  return (
    <div className="document_details">
      <div className="resume-info">
        {icon}
        <label>{label}</label>
      </div>

      <div className="d-flex align-center">
        <div className="eye_ball-border" />
        <Eye
          className={
            documentExits(fileName, employeeProfileRes)
              ? "cursor-pointer present-document"
              : ""
          }
          onClick={() => openDocument(label.toUpperCase(), employeeProfileRes)}
        />
      </div>
    </div>
  );
};
export default DocumentDetails;
