import React from "react";
import { useDispatch } from "react-redux";
import "./confirmDeleteModal.scss";
import { patchShiftRequest } from "../../redux/shiftSlice";
import { deleteEmployee } from "../../redux/employeeSlice";
import { fetchAllEmployees } from "../../redux/employeeSlice";
import { deleteAgency } from "../../redux/agencySlice";
import { deleteFacility } from "../../redux/facilitySlice";
import { deleteShift } from "../../redux/shiftSlice";
import { fetchCreatedShifts } from "../../redux/shiftSlice";

export const ConfirmDeleteModal = ({ action, setConfirmDeleteModalOpen, message }) => {
  const { name, body } = action;
  console.log("action: ", action);
  const dispatch = useDispatch();

  const rejectHandler = () => {
    if (name == "patchShiftRequest")
      dispatch(patchShiftRequest(body)).then((data) => {
        data.meta.requestStatus == "fulfilled" && setConfirmDeleteModalOpen(false);
      });
    if (name == "deleteAgency")
      dispatch(deleteAgency(body)).then((data) => {
        data.meta.requestStatus == "fulfilled" && setConfirmDeleteModalOpen(false);
      });

    if (name == "deleteFacility")
      dispatch(deleteFacility(body)).then((data) => {
        data.meta.requestStatus == "fulfilled" && setConfirmDeleteModalOpen(false);
      });

    if (name == "deleteEmployee")
      dispatch(deleteEmployee(body.employeeUUID)).then((data) => {
        if (data.meta.requestStatus == "fulfilled") {
          // const newEmployeeFetched = dispatch(fetchAllEmployees()).then(
          //   (data) =>
          //     data.meta.requestStatus == "fulfilled" &&
          //     setConfirmDeleteModalOpen(false)
          //     );
          setConfirmDeleteModalOpen(false);
          // newEmployeeFetched ;
        }
      });

    if (name === "deleteShift")
      dispatch(deleteShift(body.shiftUUID)).then((data) => {
        if (data.meta.requestStatus == "fulfilled") {
          console.log(data);
          fetchCreatedShifts();
          setConfirmDeleteModalOpen(false);
        }
      });
  };

  return (
    <div className="confirm-delete-modal-wrapper">
      <div className="confirm-delete-modal">
        <div className="confirm-text">{message}</div>
        <div className="buttons">
          <button className="button reject cursor-pointer" onClick={rejectHandler}>
            Yes
          </button>
          <button className="button accept cursor-pointer" onClick={() => setConfirmDeleteModalOpen(false)}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};
