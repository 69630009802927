import { RequestCard } from "../../components";
import { AppBar, SideBarMenu } from "../../components";
import { NotificationHomePageIcon, PendingRequest, AcceptRequest, TrueCheck } from "../../assets/images";
import { FetchShiftRequests } from "../../redux/shiftSlice";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import faker from "faker";

import { setApplication } from "../../redux/tempStorageSlice";

import { fetchTopRatedEmployee, fetchEmployeeApplications } from "../../redux/employeeSlice";

import "./agencyDashboard.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { fetchNotification } from "../../redux/notificationsSlice";

import { fetchAgencyGraphData } from "../../redux/agencySlice";

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
    },
  },
};

const generateLabels = () => {
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const today = new Date();
  const days = [];
  for (let i = 6; i >= 0; i--) {
    const date = new Date();
    date.setDate(today.getDate() - i);
    days.push({
      name: daysOfWeek[date.getDay()],
      date: date.getDate(),
    });
  }
  return days;
};

const labels = generateLabels().map((day) => day.name);
// console.log("labels: ", labels);

const AgencyDashboard = () => {
  const dispatch = useDispatch();

  // const [counts, setCounts] = useState({
  //   pending: "...",
  //   approved: "...",
  // });
  const [countPending, setCountPending] = useState("...");
  const [countApproved, setCountApproved] = useState("...");

  useEffect(() => {
    dispatch(fetchTopRatedEmployee());
    dispatch(fetchEmployeeApplications());
    dispatch(fetchNotification());
    dispatch(fetchAgencyGraphData("_"));
  }, [dispatch]);

  const topRatedEmployeesSubSlice = useSelector((state) => state.employeeSlice.topRatedEmployees);
  const employeeApplicationsSubSlice = useSelector((state) => state.employeeSlice.employeeApplications);
  const notificationsSubSlice = useSelector((state) => state.notificationsSlice);
  const agencyGraphData = useSelector((state) => state.agencySlice.agencyGraphData);
  const shiftRequestsSubSlice = useSelector((state) => state.shiftSlice.fetchShiftRequests);
  const uuid = useSelector((state) => state.userInfoSlice?.res?.profile?.user?.uuid);
  console.log("uuid: ", uuid);

  useEffect(() => {
    dispatch(FetchShiftRequests({ uuid }));
  }, [dispatch, uuid]);

  let data;
  if (agencyGraphData.status == "success")
    data = {
      labels,
      datasets: [
        {
          label: "Shifts Count",
          data: agencyGraphData.res.map((item) => item.n),
          borderColor: "rgb(255, 99, 132)",
          backgroundColor: "rgba(255, 99, 132, 0.5)",
        },
        // {
        //   label: "Dataset 2",
        //   data: labels.map(() => faker.datatype.number({ min: -1000, max: 1000 })),
        //   borderColor: "rgb(53, 162, 235)",
        //   backgroundColor: "rgba(53, 162, 235, 0.5)",
        // },
      ],
    };

  useEffect(() => {
    if (Array.isArray(topRatedEmployeesSubSlice.res)) {
      const tempCountApproved = topRatedEmployeesSubSlice.res.filter((obj) => obj.profile?.is_active === true).length;
      console.log("tempCountApproved: ", tempCountApproved);
      setCountApproved(tempCountApproved);
    }
    // if (!Array.isArray(employeeApplicationsSubSlice.res)) return; //guard clause
    // countsTemp = employeeApplicationsSubSlice.res.reduce((acc, obj) => {
    //   acc.pending++;
    //   return acc;
    // }, countsTemp);

    if (!Array.isArray(shiftRequestsSubSlice.res)) return; //guard clause
    const tempCountPendingShiftRequest = shiftRequestsSubSlice.res.filter((value, index, array) => {
      value.status === "Pending";
    }).length;
    setCountPending(tempCountPendingShiftRequest);

    // setCounts({ ...countsTemp });
  }, [topRatedEmployeesSubSlice.res, employeeApplicationsSubSlice.res, shiftRequestsSubSlice.res]);

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />
        <div className="homepage_content">
          <div className="super-admin-container agency-dashboard">
            <div className="super-admin">
              <div className="dashboard-header">Dashboard</div>
              <div className="request-card-container">
                <div className="flex-1" onClick={() => dispatch(setApplication("Employee"))}>
                  <RequestCard
                    name="Pending Requests"
                    count={countPending}
                    requestLogo={<PendingRequest />}
                    link="/shift-requests"
                  />
                </div>
                <div className="flex-1">
                  <RequestCard
                    name="Approved Requests"
                    count={countApproved}
                    requestLogo={<AcceptRequest />}
                    link="/available-now"
                  />
                </div>
              </div>
              <div className="recent-agencies-header">Scheduled Hours</div>
              {agencyGraphData.status == "success" && <Line options={options} data={data} className="line-chart" />}
            </div>
            <div className="side-notifications">
              <div className="alerts-header">
                <div className="alerts">Alerts</div>
                <Link to="/notifications" className="text-deco-none">
                  <div className="view-notifications">View Notifications</div>
                </Link>
              </div>
              {notificationsSubSlice.status === "loading" && !Array.isArray(notificationsSubSlice?.res) ? (
                <div style={{ display: "flex", margin: "auto", marginTop: "64px" }} className="loader margin"></div>
              ) : notificationsSubSlice?.status === "success" && notificationsSubSlice?.res?.length > 0 ? (
                notificationsSubSlice?.res.slice(0, 5).map((notification) => (
                  <div className="notification-card">
                    <NotificationHomePageIcon />
                    <div className="notification-content-container">
                      <div className="notication-content">{notification.message}</div>
                      <div className="notification-time">{formatTime(notification.created_at)}</div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="notification-card">
                  <TrueCheck />
                  <div className="notification-content-container">
                    <div className="notication-content">No new notifications. All caught up!</div>
                    <div className="notification-time"></div>
                  </div>
                </div>
              )}
              {/* {[...Array(6).keys()].map((e) => (
                <div className="notification-card">
                  <NotificationHomePageIcon />
                  <div className="notification-content-container">
                    <div className="notication-content">
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit
                      Aenean sit
                    </div>
                    <div className="notification-time">10:35 PM</div>
                  </div>
                </div>
              ))} */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  function formatTime(dateString) {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
    return formattedTime;
  }
};

export default AgencyDashboard;
