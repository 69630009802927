import { RadioGroup } from "@mui/material";
import MyFormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { useState } from "react";
import { ModalCloseIcon } from "../../assets/images";
import "./createAgencyModal.scss";

import { useDispatch } from "react-redux";
import { createNewAgency } from "../../redux/agencySlice";

const CreateAgencyModal = ({ setModalOpen }) => {
  const [name, setName] = useState();
  const [userName, setUserName] = useState();
  const [password, setPassword] = useState("no");
  const [confirmPassword, setConfirmPassword] = useState("no");
  const [email, setEmail] = useState();
  const [about, setAbout] = useState();
  const [approve, setApprove] = useState();
  const [del, setDel] = useState();
  const [error, setError] = useState();
  const [status, setStatus] = useState();

  const dispatch = useDispatch();

  const addAgencyClickHandler = () => {
    let permissions = [approve && "AE", del && "DE"];
    permissions = permissions.filter((value) => value != false);

    if (
      !(
        name &&
        userName &&
        password &&
        confirmPassword &&
        email &&
        about &&
        approve != undefined &&
        del != undefined
      )
    ) {
      return setError("Please fill all the fields");
    } else if (password != confirmPassword) {
      return setError("Passwords don't match");
    } else {
      setError();
      const body = {
        profile: {
          user: {
            username: userName,
            email: email,
            password: password,
            role: "Agency",
          },
        },
        name: name,
        permissions: permissions,
        about: about,
      };

      dispatch(createNewAgency(body)).then((res) => {
        res.meta.requestStatus == "fulfilled" &&
          setStatus(res.meta.requestStatus);

        setError(Object.values(res.payload)[0]);
      });
    }
  };

  if (status == "fulfilled") {
    return (
      <div className="root">
        <div className="create-agency" style={{ height: "484px" }}>
          <ModalCloseIcon
            onClick={() => setModalOpen(false)}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "16px",
              right: "24px",
              zIndex: "1000",
            }}
          />
          <h2
            style={{
              color: "green",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              fontWeight: "500",
              fontSize: "20px",
            }}
          >
            Agency Created successfully
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="root">
      <div className="create-agency">
        <div className="create-agency-header">
          <div className="create-agency-label">Create New Agency</div>
          <ModalCloseIcon
            onClick={() => setModalOpen(false)}
            style={{ cursor: "pointer" }}
          />
        </div>
        <div className="provide-details">
          Please provide details to add agency
        </div>
        <div className="agency-modal-fields">
          <input
            type="text"
            placeholder="Name"
            className=""
            onChange={(e) => setName(e.target.value)}
          />
          <input
            type="text"
            placeholder="Username"
            onChange={(e) => setUserName(e.target.value)}
          />
        </div>

        <div className="agency-modal-fields">
          <input
            type="email"
            placeholder="Email"
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            type="text"
            placeholder="About"
            onChange={(e) => setAbout(e.target.value)}
          />
        </div>
        <div className="roles-permissions">Roles & Permissions</div>
        <div className="multi-choice">
          <div className="agency-multiple-qs">Can approve employees</div>
          <div>
            <RadioGroup
              name="radio-buttons-group"
              style={{ flexDirection: "row" }}
            >
              <MyFormControlLabel
                value="first"
                label="Yes"
                control={<Radio />}
                className="multi-text"
                onClick={() => setApprove(true)}
              />
              <MyFormControlLabel
                value="second"
                label="No"
                control={<Radio />}
                className="multi-text"
                onClick={() => setApprove(false)}
              />
            </RadioGroup>
          </div>
        </div>
        <div className="multi-choice">
          <div className="agency-multiple-qs">Can delete employees</div>
          <div>
            <RadioGroup
              name="radio-buttons-group"
              style={{ flexDirection: "row" }}
            >
              <MyFormControlLabel
                value="first"
                label="Yes"
                control={<Radio />}
                className="multi-text"
                onClick={() => setDel(true)}
              />
              <MyFormControlLabel
                value="second"
                label="No"
                control={<Radio />}
                className="multi-text"
                onClick={() => setDel(false)}
              />
            </RadioGroup>
          </div>
        </div>
        <div className="error-div" style={{ color: "red", fontSize: "14px" }}>
          {error}
        </div>
        <div
          className="add-agency-btn"
          style={{ cursor: "pointer" }}
          onClick={() => addAgencyClickHandler()}
        >
          <span>Add Agency</span>
        </div>
      </div>
    </div>
  );
};

export default CreateAgencyModal;
