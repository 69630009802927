import { AppBar, ProfileSettingsBtn, SideBarMenu } from "../../components";
import "./profileSettingsPage.scss";
import {
  NotificationDetailsIcon,
  ChevronRight,
  LogoutIcon,
  AccountDetailsIcon,
  MayoClinic,
  Camera,
  ChevronLeft,
  HospitalFacility,
  FacilityBuilding,
  FacilityEmail,
  FacilityLocation,
  Maya,
} from "../../assets/images";

import useCookies from "react-cookie/cjs/useCookies";
import { clearFetchUserLogin } from "../../redux/userLoginSlice.js";
import { clearFetchUserInfo } from "../../redux/userInfoSlice";
import { useDispatch } from "react-redux";

import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

import { getImage } from "../../utils/getAwsFile";

import { RiLockPasswordLine } from "react-icons/ri";

export const removeAllCookiesJs = () => {
  // Get all cookies
  var cookies = document.cookie.split(";");
  // Loop through each cookie and delete it
  for (var i = 0; i < cookies.length; i++) {
    var cookie = cookies[i];
    var eqPos = cookie.indexOf("=");
    var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
  }
};

const ProfileSettingsPage = () => {
  const [cookies, setCookie, removeCookie] = useCookies([]);

  const [token] = useCookies(["token"]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState("true");

  const [picture, setPicture] = useState(null);

  const userInfoRes = useSelector((state) => state?.userInfoSlice?.res);

  useEffect(() => {
    if (userInfoRes?.files && userInfoRes?.files[0]?.aws_object_key) {
      getImage(userInfoRes.files[0].aws_object_key).then((data) => {
        setPicture(data);
        setLoading("false");
      });
    }
    userInfoRes?.files &&
      userInfoRes?.files.length == "0" &&
      setLoading("false");
    !userInfoRes?.files && setLoading("false");
  }, [userInfoRes]);

  const logoutHandler = () => {
    //  setCookie("fcm","send")

    if (token.token.user_type === "Admin") {
      dispatch(clearFetchUserLogin());
      dispatch(clearFetchUserInfo());
      removeAllCookiesJs();

      removeCookie("activeMenu");
      removeCookie("token");
      navigate("/login-admin");
    } else {
      dispatch(clearFetchUserLogin());
      dispatch(clearFetchUserInfo());
      removeCookie("activeMenu");
      removeCookie("token");
    }
  };

  return (
    <div className="homepage">
      <SideBarMenu />
      <div className="homepage_container">
        <AppBar />

        {loading == "true" && (
          <div style={{ margin: "auto" }} className="loader margin"></div>
        )}

        {loading == "false" && (
          <div className="homepage_content">
            <div className="profile_homepage">
              <div className="profile_homepage_header"> Profile Settings</div>
              <div className="profile_homepage_dropzone">
                <div className="uploader">
                  <img
                    style={{
                      width: "127px",
                      height: "127px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      borderRadius: "13px",
                    }}
                    src={picture ? "data:image/jpeg;base64," + picture : Maya}
                    className="mayo-icon"
                  />
                </div>
                <div className="uploader_name">{userInfoRes?.name}</div>
                <div className="uploader_email">
                  {userInfoRes?.profile?.user?.email}
                </div>
              </div>
              {cookies.token.user_type == "Facility" && (
                <Link
                  to={`/facility-details/${userInfoRes?.profile?.user?.uuid}`}
                  style={{ textDecoration: "none" }}
                >
                  <ProfileSettingsBtn
                    settingOptions={<AccountDetailsIcon />}
                    content="Account Details"
                    viewMore={<ChevronRight />}
                  />
                </Link>
              )}
              <Link to="/notifications" style={{ textDecoration: "none" }}>
                <ProfileSettingsBtn
                  settingOptions={<NotificationDetailsIcon />}
                  content="Notifications"
                  viewMore={<ChevronRight />}
                />
              </Link>

              <Link to="/change-password" style={{ textDecoration: "none" }}>
                <ProfileSettingsBtn
                  settingOptions={
                    <div
                      style={{
                        height: "32px",
                        width: "32px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        background: "rgb(0 0 238 / 10%)",
                        borderRadius: "4px",
                      }}
                    >
                      <RiLockPasswordLine />
                    </div>
                  }
                  content="Change Password"
                  viewMore={<ChevronRight />}
                />
              </Link>

              <span onClick={logoutHandler}>
                <ProfileSettingsBtn
                  settingOptions={<LogoutIcon />}
                  content="Log out"
                />
              </span>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProfileSettingsPage;
