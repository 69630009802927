import { createSlice } from "@reduxjs/toolkit";
import { createAsyncThunk } from "@reduxjs/toolkit";

import axios from "axios";

export const fetchUserInfo = createAsyncThunk(
  "FetchUserInfo",
  async (token, thunk) => {
    if (token === null || token === undefined)
      throw new Error("no cookie found");
    if (Object.keys(thunk.getState().userInfoSlice.res).length)
      return thunk.getState().userInfoSlice.res;

    const user_type = token.user_type;
    axios.defaults.headers.common["Authorization"] = `Bearer ${token.access}`;

    if (Object.keys(thunk.getState().userInfoSlice.res).length !== 0)
      return thunk.getState().userInfoSlice.res;

    let response;

    if (user_type === "Facility") {
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/facilities/profile`
      );
    } else if (user_type === "Agency") {
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agencies/profile`
      );
    } else if (user_type === "Admin") {
      response = await axios.get(
        `${process.env.REACT_APP_API_URL}/superadmins/profile`
      );
    }

    console.log("response: ", response);
    return response.data;
  }
);

export const patchUserImages = createAsyncThunk(
  "patchUserImages",
  async ({ token, files }) => {
    const user_type = token.user_type;

    let response;

    if (user_type === "Facility") {
      response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/facilities/profile`,
        { files }
      );
    } else if (user_type === "Agency") {
      response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/agencies/profile`,
        { files }
      );
    } else throw new Error("user type not supported");

    return response.data;
  }
);

const initialState = {
  status: "initial state",
  res: {},
  error: null,
  patch: {
    status: "initial state",
    res: {},
    error: null,
  },
  profilePicture: "",
};
const fetchUserSlice = createSlice({
  name: "fetchUserSlice",
  initialState,
  reducers: {
    clearFetchUserInfo: () => initialState,
    updateProfilePicture: (state, action) => {
      state.profilePicture = action.payload;
    },
  },
  extraReducers: {
    [fetchUserInfo.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUserInfo.fulfilled]: (state, action) => {
      state.status = "success";
      state.res = action.payload;
      state.error = null;
    },
    [fetchUserInfo.rejected]: (state, action) => {
      state.status = "failed";
      state.res = {};
      state.error = action.error;
    },

    [patchUserImages.pending]: (state, action) => {
      state.patch.status = "loading";
    },
    [patchUserImages.fulfilled]: (state, action) => {
      state.patch.status = "success";
      state.res = action.payload;
      state.patch.error = null;
    },
    [patchUserImages.rejected]: (state, action) => {
      state.patch.status = "failed";
      state.patch.res = {};
      state.patch.error = action.error;
    },
  },
});

export const { clearFetchUserInfo, updateProfilePicture } =
  fetchUserSlice.actions;
export default fetchUserSlice.reducer;
