import AWS from "aws-sdk";
AWS.config.update({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
});

var s3 = new AWS.S3();

export const getImage = async (key) => {
  console.log("getImage key: ", key);
  try {
    const params = {
      Bucket: "elasticbeanstalk-us-east-1-323377162080",
      Key: key,
    };

    const data = await s3.getObject(params).promise();

    return data.Body.toString("base64");
  } catch (e) {
    throw new Error(`Could not retrieve file from S3: ${e.message}`);
  }
};
