import { configureStore } from "@reduxjs/toolkit";

import userLoginSliceReducer from "./userLoginSlice.js";
import userInfoSliceReducer from "./userInfoSlice.js";
import employeeSliceReducer from "./employeeSlice.js";
import shiftSlice from "./shiftSlice.js";
import agencySliceReducer from "./agencySlice.js";
import facilitySliceReducer from "./facilitySlice.js";
import tempStorageSliceReducer from "./tempStorageSlice.js";
import notificationsSliceReducer from "./notificationsSlice.js";
const store = configureStore({
  reducer: {
    userLoginSlice: userLoginSliceReducer,
    userInfoSlice: userInfoSliceReducer,
    employeeSlice: employeeSliceReducer,
    shiftSlice: shiftSlice,
    agencySlice: agencySliceReducer,
    facilitySlice: facilitySliceReducer,
    tempStorageSlice: tempStorageSliceReducer,
    notificationsSlice: notificationsSliceReducer,
  },
  devTools: process.env.NODE_ENV === "development" ? true : false,
});

export default store;
