import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import axios from "axios";
import Cookies from "universal-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyCQ2ooyVAFi0CBKOwu0YIAFZ4TIinst7qw",
  authDomain: "nod-api-98d93.firebaseapp.com",
  projectId: "nod-api-98d93",
  storageBucket: "nod-api-98d93.appspot.com",
  messagingSenderId: "598962365124",
  appId: "1:598962365124:web:78674061edcbb9f55b9f91",
  measurementId: "G-C2MZN5L0VG",
};

const cookies = new Cookies();

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

const registerDevice = async (token) => {
  await axios
    .post(`${process.env.REACT_APP_API_URL}/notifications/register`, {
      device_id: token,
    })
    .then((data) => console.log("data:...", data));
};

export const takeToken = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey: "BJ6dqB85d6rZIr9j84-zDWJhIilbpR11321PSeRu3q57QIhtDGAed-3ep7gXfmny9xRR2nZQIotUJttJghVlKfM",
  })
    .then((currentToken) => {
      // console.log("currentToken: ", currentToken);
      if (currentToken) {
        const fcmStored = cookies.get("fcm");
        const accessToken = cookies.get("token");
        // if user isn't logged in, don't register device

        if (!accessToken) return;
        if (fcmStored === "send") {
          registerDevice(currentToken);
          cookies.set("fcm", "sent");
        }

        // console.log("current token for client: ", currentToken);

        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        console.log("No registration token available. Request permission to generate one.");
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      resolve(payload);
    });
  });
