import { async } from "@firebase/util";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { sortGraphData } from "../utils/agencyGraphData";

// fetchAgencyProfile
export const fetchAgencyProfile = createAsyncThunk(
  "Agency/FetchProfile",
  async ({ uuid }) => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agencies/${uuid}`
    );
    return response.data;
  }
);

// FETCH AGENCIES LIST
export const fetchAgenciesList = createAsyncThunk(
  "Agency/agenciesList",
  async () => {
    const response = await axios.get(
      `${process.env.REACT_APP_API_URL}/agencies/`
    );
    return response.data;
  }
);

// CREATE AGENCY
export const createNewAgency = createAsyncThunk(
  "Agency/CreateNew",
  async (body, thunk) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/agencies/create`,
        body
      );
      return response.data;
    } catch (error) {
      // console.log("error: ", error);
      return thunk.rejectWithValue(error.response.data.profile.user);
    }
  }
);

// FETCH ALL FACILITIES
export const fetchAllFacilites = createAsyncThunk(
  "Agency/fetchAllFacilites",
  async (uuid) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/agencies/${uuid}/facilities`
      );
      return response.data;
    } catch (error) {
      // console.log("error: ", error);
    }
  }
);

export const patchAgencyPermission = createAsyncThunk(
  "Agency/patchAgencyPermission",
  async (
    { uuid, permissionsArray: permissions },
    { rejectWithValue, dispatch }
  ) => {
    // console.log("permissionsArray: ", permissionsArray);
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/agencies/${uuid}`,
        { permissions }
      );
      dispatch(fetchAgenciesList());
      return response.data;
    } catch (error) {
      // console.log("error: ", error);
      rejectWithValue(error);
    }
  }
);

// deleteAgency
export const deleteAgency = createAsyncThunk(
  "deleteAgency",
  async (agencyUUID, { dispatch }) => {
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/employees/${agencyUUID}/delete`
      );
      // dispatch(fetchAllEmployees());
      return agencyUUID;
    } catch (err) {
      console.log(err);
      // dispatch(fetchAllEmployees());
    }
  }
);

export const fetchAgencyGraphData = createAsyncThunk(
  "Agency/fetchAgencyGraphData",
  async (_, { rejectWithValue }) => {
    // console.log("in fetch graph data");

    function getCurrentMonthBody() {
      const today = new Date();
      return {
        year: today.getFullYear(),
        month: today.getMonth() + 1,
      };
    }
    function getPreviousMonthBody() {
      const { year, month } = getCurrentMonthBody();
      return {
        year: month === 1 ? year - 1 : year,
        month: month === 1 ? 12 : month - 1,
      };
    }
    const currentMonthBody = getCurrentMonthBody();
    const pastMonthBody = getPreviousMonthBody();

    const currentMonthData = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/count?month=${currentMonthBody.month}&year=${currentMonthBody.year}`
    );
    const previousMonthData = await axios.get(
      `${process.env.REACT_APP_API_URL}/shifts/count?month=${pastMonthBody.month}&year=${pastMonthBody.year}`
    );
    const sortedData = sortGraphData(
      getPreviousMonthBody(),
      previousMonthData.data,
      getCurrentMonthBody(),
      currentMonthData.data
    );
    return sortedData;
  }
);

const initialState = {
  Profile: {
    status: "initial state",
    res: {},
    error: null,
  },
  agenciesList: {
    status: "initial state",
    res: {},
    error: null,
  },
  createNewAgency: {
    status: "initial state",
    res: {},
    error: null,
  },
  agencyFalities: {
    status: "initial state",
    res: {},
    error: null,
  },
  agencyGraphData: {
    status: "initial state",
    res: {},
    error: null,
  },
};
const facilitySlice = createSlice({
  name: "facilitySlice",
  initialState,
  extraReducers: {
    // fetchAgencyProfile

    [fetchAgencyProfile.pending]: (state) => {
      state.Profile.status = "loading";
      state.Profile.res = {};
    },
    [fetchAgencyProfile.fulfilled]: (state, action) => {
      state.Profile.status = "success";
      state.Profile.res = action.payload;
      state.Profile.error = null;
    },
    [fetchAgencyProfile.rejected]: (state, action) => {
      state.Profile.status = "failed";
      state.Profile.res = {};
      state.Profile.error = action.error;
    },

    // agenciesList

    [fetchAgenciesList.pending]: (state) => {
      state.agenciesList.status = "loading";
    },
    [fetchAgenciesList.fulfilled]: (state, action) => {
      state.agenciesList.status = "success";
      state.agenciesList.res = action.payload;
      state.agenciesList.error = null;
    },
    [fetchAgenciesList.rejected]: (state, action) => {
      state.agenciesList.status = "failed";
      state.agenciesList.res = {};
      state.agenciesList.error = action.error;
    },

    // CREATE NEW AGENCY

    [createNewAgency.pending]: (state) => {
      state.createNewAgency.status = "loading";
    },
    [createNewAgency.fulfilled]: (state, action) => {
      state.createNewAgency.status = "success";
      state.createNewAgency.res = action.payload;
      state.createNewAgency.error = null;
    },
    [createNewAgency.rejected]: (state, action) => {
      state.createNewAgency.status = "failed";
      state.createNewAgency.res = {};
      state.createNewAgency.error = action.error;
    },

    // Fetch all agency facilities

    [fetchAllFacilites.pending]: (state) => {
      state.agencyFalities.status = "loading";
    },
    [fetchAllFacilites.fulfilled]: (state, action) => {
      state.agencyFalities.status = "success";
      state.agencyFalities.res = action.payload;
      state.agencyFalities.error = null;
    },
    [fetchAllFacilites.rejected]: (state, action) => {
      state.agencyFalities.status = "failed";
      state.agencyFalities.res = {};
      state.agencyFalities.error = action.error;
    },

    // Fetch  agency graph data

    [fetchAgencyGraphData.pending]: (state) => {
      state.agencyGraphData.status = "loading";
    },
    [fetchAgencyGraphData.fulfilled]: (state, action) => {
      state.agencyGraphData.status = "success";
      state.agencyGraphData.res = action.payload;
      state.agencyGraphData.error = null;
    },
    [fetchAgencyGraphData.rejected]: (state, action) => {
      state.agencyGraphData.status = "failed";
      state.agencyGraphData.res = {};
      state.agencyGraphData.error = action.error;
    },

    // deleteAgency
    [deleteAgency.pending]: (state) => {
      state.agenciesList.status = "loading";
    },
    [deleteAgency.fulfilled]: (state, action) => {
      state.agenciesList.status = "success";
      state.agenciesList.res = state.agenciesList.res.filter(
        (agency) => agency.uuid !== action.payload
      );
      state.agenciesList.error = null;
    },
  },
});

export default facilitySlice.reducer;
