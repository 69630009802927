import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export const fetchUserLogin = createAsyncThunk(
  "FetchUserLogin",
  async ({ email, password, activeLoginUserType }, thunk) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        {
          email,
          password,
        },
      );

      if (response.data.user_type !== activeLoginUserType) {
        return thunk.rejectWithValue("Invalid email/password.");
      } else {
        cookies.set("fcm", "send");
        return response.data;
      }
    } catch (error) {
      return thunk.rejectWithValue(error.response.data.message);
    }
  },
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async ({ email }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        `https://backend-v2.thenursebridge.com/auth/password-reset`,
        {
          email,
        },
      );

      return response.data;
    } catch (error) {
      console.log("error: ", error);
      return rejectWithValue(Object.values(error.response.data)[0][0]);
    }
  },
);

export const fetchAdminLogin = createAsyncThunk(
  "FetchAdminLogin",
  async ({ email, password }, thunk) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/users/login`,
        {
          email,
          password,
        },
      );
      if (response.data.user_type != "Admin") {
        return thunk.rejectWithValue("Invalid email/password.");
      } else {
        return response.data;
      }
    } catch (error) {
      return thunk.rejectWithValue(error.response.data.message);
    }
  },
);

export const changePassword = createAsyncThunk(
  "users/changePassword",
  async (body, { rejectWithValue }) => {
    try {
      const response = await axios.patch(
        `${process.env.REACT_APP_API_URL}/users/password_change`,
        body,
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  },
);

const initialState = {
  status: "initial state",
  res: {},
  error: null,
  passwordChange: {
    status: "initial state",
    res: {},
    error: null,
  },
  resetPassword: {
    status: "initial state",
    res: {},
    error: null,
  },
};

const userInfoSlice = createSlice({
  name: "userInfoSlice",
  initialState,
  reducers: {
    clearFetchUserLogin: () => initialState,
  },
  extraReducers: {
    [fetchUserLogin.pending]: (state) => {
      state.status = "loading";
    },
    [fetchUserLogin.fulfilled]: (state, action) => {
      state.status = "success";
      state.res = action.payload;
      state.error = null;
    },
    [fetchUserLogin.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.res = {};
      state.error = payload;
    },
    [fetchAdminLogin.pending]: (state) => {
      state.status = "loading";
    },
    [fetchAdminLogin.fulfilled]: (state, action) => {
      state.status = "success";
      state.res = action.payload;
      state.error = null;
    },
    [fetchAdminLogin.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.res = {};
      state.error = payload;
    },

    [changePassword.pending]: (state) => {
      state.passwordChange.status = "loading";
    },
    [changePassword.fulfilled]: (state, action) => {
      state.passwordChange.status = "success";
      state.passwordChange.res = action.payload;
      state.passwordChange.error = null;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.passwordChange.status = "failed";
      state.passwordChange.res = {};
      state.passwordChange.error = payload;
    },

    // reset password
    [resetPassword.pending]: (state) => {
      state.resetPassword.status = "loading";
    },
    [resetPassword.fulfilled]: (state, action) => {
      state.resetPassword.status = "success";
      state.resetPassword.res = action.payload;
      state.resetPassword.error = null;
    },
    [resetPassword.rejected]: (state, { payload }) => {
      state.resetPassword.status = "failed";
      state.resetPassword.res = {};
      state.resetPassword.error = payload;
    },
  },
});

export const { clearFetchUserLogin } = userInfoSlice.actions;
export default userInfoSlice.reducer;
